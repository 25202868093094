<template>
  <main class="d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
      <h1>-ENTER THE EDEN-</h1>
      <button @click="ophelia" class="enter">GO</button>
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    ophelia() {
      this.$router.push({ path: "/ophelias-eden" });
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  height: 100vh;
  color: #fff;
  background: #000;
}
</style>