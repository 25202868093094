<template>
  <h1 class="text-center p-5">TOUR DATES.</h1>
  <div class="d-flex justify-content-center">
    <a
      class="bit-widget-initializer"
      data-artist-name="ophelia's eden"
      data-display-local-dates="false"
      data-display-past-dates="false"
      data-auto-style="false"
      data-text-color="#000000"
      data-link-color="#e8e6e7"
      data-background-color="rgba(0,0,0,0)"
      data-display-limit="15"
      data-display-start-time="false"
      data-link-text-color="#6991b2"
      data-display-lineup="false"
      data-display-play-my-city="true"
      data-separator-color="rgba(255, 255, 255, 0.5)"
    ></a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>