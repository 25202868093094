<template>
  <!-- <div class="notification-dash position-relative mb-4">
    <Loading is-loading />
  </div> -->

  <!-- mobile -->
  <transition-group name="fade" v-if="!mobile">
    <div class="mobileHeader" v-if="header">
      <ul class="d-flex justify-content-center mb-0 mt-0">
        <li
          v-for="section in examples"
          :key="section"
          class="nav-link timeBtn notifications-card active"
          @click="goToTimeline(section.CodedId)"
        >
          <div id="active" v-if="section.CodedId === activeEntry"></div>
          <div class="notifications-card__body">
            <div>
              <p class="titleMobile">
                {{ section.title }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </transition-group>

  <!-- desktop -->
  <transition-group name="fade" v-else>
    <div class="desktopHeader" v-if="header">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="OE d-flex justify-content-center align-items-center m-0 p-0 mt-1"
          @click="home"
        >
          <p class="m-2 p-0">OE</p>
        </div>
      </div>
      <nav>
        <ul class="d-flex justify-content-center mb-0 mt-0">
          <li
            v-for="section in examples"
            :key="section"
            class="nav-link timeBtn notifications-card m-1 active"
            @click="goToTimeline(section.CodedId)"
          >
            <div id="active" v-if="section.CodedId === activeEntry"></div>
            <div class="notifications-card__body">
              <div>
                <p class="title">
                  {{ section.title }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </transition-group>

  <!-- Intro -->

  <img src="@/assets/background/Blue2.png" class="img" alt="" />

  <!-- Sections -->

  <div class="pages">
    <section
      v-for="section in examples"
      :key="section"
      :name="section.CodedId"
      class="observable"
      ref="section"
    >
      <main>
        <!-- About -->

        <template v-if="section.about">
          <div class="about">
            <About />
            <div class="filler"></div>
            <div class="filler backs" :style="bgImg1"></div>
          </div>
        </template>

        <!-- Music -->

        <template v-if="section.music">
          <div class="music">
            <Music />
            <div class="filler"></div>
            <div class="filler backs" :style="bgImg2"></div>
          </div>
        </template>

        <!-- Tour Dates -->

        <template v-if="section.tour">
          <div class="tour">
            <Tours />
            <div class="filler"></div>
          </div>
        </template>

        <!-- Contact -->

        <template v-if="section.contact">
          <div class="contact2">
            <Contact />
            <div class="filler"></div>
          </div>
        </template>
      </main>
    </section>
  </div>

  <!-- scroll to top -->
  <transition name="fade">
    <button
      @click="scrollToTop"
      class="topBtn rounded-circle button-88"
      v-if="scroller"
    >
      <font-awesome-icon icon="fa-solid fa-caret-up" class="upArrow" /></button
  ></transition>

  <footer>
    <div class="d-flex justify-content-center align-items-center">
      <p class="privacy m-3 p-0">2023 OPHELIA'S EDEN</p>
    </div>
  </footer>
</template>

<script>
import About from "@/pages/About.vue";
import Music from "@/pages/Music.vue";
import Tours from "@/pages/Tours.vue";
import Contact from "@/pages/Contact.vue";
export default {
  name: "OE",
  components: {
    About,
    Music,
    Tours,
    Contact,
  },
  data() {
    return {
      mobile: null,
      header: true,
      scroller: false,
      activeEntry: "",
      examples: [
        {
          title: "ABOUT.",
          about: true,
          sub: "Musician",
          CodedId: "1",
        },
        {
          title: "MUSIC.",
          music: true,
          sub: "",
          experience: true,
          CodedId: "2",
        },
        {
          title: "TOUR DATES.",
          tour: true,
          CodedId: "3",
        },
        {
          title: "CONTACT.",
          contact: true,
          CodedId: "4",
        },
      ],
      bgImg1: {
        backgroundImage: `url(${require("../assets/background/b&w.jpg")})`,
      },
      bgImg2: {
        backgroundImage: `url(${require("../assets/images/C4.jpg")})`,
      },
    };
  },
  methods: {
    home() {
      this.$router.push({ path: "/" });
    },
    handleResize() {
      if (window.innerWidth <= 1024) {
        //on mobile
        this.mobile = false;
      } else {
        this.mobile = true;
      }
    },
    goToSection() {
      const matchingSections = document.getElementsByName(this.activeEntry);
      if (matchingSections.length === 1) {
        if (this.isMobile === true) {
          window.scrollTo({
            top: matchingSections[0].offsetTop,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: matchingSections[0].offsetTop,
            behavior: "instant",
          });
        }
      }
    },
    goToTimeline(sectionName) {
      const matchingSections = document.getElementsByName(sectionName);
      if (matchingSections.length === 1) {
        window.scrollTo({
          top: matchingSections[0].offsetTop,
          behavior: "smooth",
        });
      }
    },
    scrollToTop() {
      if (this.isMobile === true) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    scrollTo() {
      var myElement = document.getElementById("active");
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    handleHeader() {
      if ((this.scrolled = window.scrollY > 200)) {
        this.header = false;
      } else {
        this.header = true;
      }
    },
    handleScroll() {
      if ((this.scrolled = window.scrollY > 300)) {
        this.scroller = true;
      } else {
        this.scroller = false;
      }
    },
    observeSections() {
      const highlighted = document.getElementsByClassName("observable");
      for (let i = 0; i < highlighted.length; i++) {
        this.observe(highlighted[i]);
      }
    },
    observe(entry) {
      this.observer.observe(entry);
    },
    initObserver() {
      const options = {
        threshold: [0.5],
      };
      this.observer = new IntersectionObserver((entries) => {
        const active = entries.filter((e) => e.isIntersecting); //entry.intersectionRatio
        if (active.length > 0) {
          this.activeEntry = active[0].target.attributes.name.value;
          setTimeout(() => {
            this.scrollTo();
          }, 800);
        }
      }, options);
    },
  },
  computed: {
    // noData() {
    //   return this.caseData.length === 0;
    // },
  },
  mounted() {
    this.handleResize();
    this.initObserver();
    this.observeSections();
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleHeader);
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik+Dirt&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.cdnfonts.com/css/sable-lion");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.timelineScroll {
  overflow-y: scroll;
  height: 600px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.notification-dash::-webkit-scrollbar {
  display: none;
}
.scrollable::-webkit-scrollbar {
  display: none;
}
.notifications-timestamp::first-letter {
  text-transform: uppercase;
}
.notifications-card__body {
  z-index: 2;
}
.notification {
  z-index: 3;
  top: -5px;
  right: -10px;
  position: absolute;
  box-shadow: 0 2px 2px 0 rgba(34, 35, 38, 0.7);
}
.timeBtn {
  height: auto;
  border: none;
  color: #c0b8b888;
  &:hover {
    color: #262525;
    transition: 0.6s;
  }
}
.active {
  color: #000000;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
  color: #000;
  cursor: pointer;
}
.noData {
  color: #b3b3b3;
}
.topBtn {
  position: fixed;
  bottom: 10%;
  right: 5%;
  background: #baffc8;
  width: 60px;
  height: 60px;
  color: #000;
  border: none;
  box-shadow: 1px 2px 2px 1px rgba(34, 35, 38, 0.5);
  transition: 0.3s;
  &:hover {
    background: #62bee4;
    transition: 0.6s;
  }
}
main {
  color: #5e5e5f;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: rgba(245, 201, 135, 0.505);
  }
}
.OE {
  border-radius: 100px;
  font-size: 2rem;
  color: #fff;
  background-color: #baffc8;
  font-family: "Cinzel", serif;
  cursor: pointer;
}
nav {
  height: 50px;
  box-shadow: 0 2px 2px 0 rgba(34, 35, 38, 0.7);
}
.desktopHeader {
  background: #62bee4;
  position: fixed;
  width: 100%;
}
.mobileHeader {
  position: fixed;
  width: 100%;
}
.title {
  color: #baffc8;
  font-size: 1.5rem;
  font-family: "Sable Lion", sans-serif;
}
.titleMobile {
  color: #baffc8;
  font-size: 1rem;
  font-family: "Sable Lion", sans-serif;
}
.upArrow {
  color: #fff;
}
.img {
  max-width: 100%;
}
footer {
  height: auto;
  color: #ff5c5e;
  background: #fdedf8;
}
.privacy:before {
  content: "\00a9";
}
h1 {
  font-family: "Sable Lion", sans-serif;
}
.aboutLayout,
h5,
footer {
  font-family: "Cinzel", serif;
}
h5 {
  @media (max-width: 1000px) {
    width: 90vw;
  }
}
iframe {
  width: 50vw;
}
.tours {
  background: #fff;
  border: 1pc solid #000;
  width: 50vw;
}
.contact-form-wrapper {
  padding: 100px 0;
}

.contact-form {
  padding: 30px 40px;
  background-color: #ffffff;
  border-radius: 12px;
  max-width: 400px;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input,
.form-text-area {
  background-color: #f0f4f5;
  height: 50px;
  padding-left: 16px;
}

.contact-form .form-text-area {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contact-form .form-control::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control:focus {
  border-color: #f33fb0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #f33fb0;
}

.contact-form .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.contact-form .description {
  color: #aeb4b9;
  font-size: 14px;
  text-align: center;
}

.contact-form .submit-button-wrapper {
  text-align: center;
}

.contact-form .submit-button-wrapper input {
  border: none;
  border-radius: 4px;
  background-color: #f23292;
  color: white;
  text-transform: uppercase;
  padding: 10px 60px;
  font-weight: 500;
  letter-spacing: 2px;
}

.contact-form .submit-button-wrapper input:hover {
  background-color: #d30069;
}
.filler {
  height: 30vh;
}
.backs {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
iframe {
  width: 50vw;
  @media (max-width: 1000px) {
    width: 90vw;
  }
}
.about {
  background: #fff;
}
.music {
  background: #f8fdcd;
}
.tour {
  background: #fdedf8;
}
.contact2 {
  background: #e9c9fd;
}
</style>