<template>
  <div class="d-flex flex-column">
    <h1 class="text-center p-5">ABOUT.</h1>
    <p class="text-center aboutLayout container">
      Ophelia's Eden is the brainchild of Ciara Gayer, who is on a mission to
      inspire people with her music. Tired of negativity surrounding what seems
      like world crisis after crisis, Ciara created an album that weaves modern
      synths and punchy rhythms with a sprinkle of magical harmonies, strings
      and wind instruments.<br /><br />
      Ciara's live performances are not to be missed, her voice resonates power
      and truth as she sings from her heart and soul and will intertwine with
      yours as well.<br /><br />
      Her upcoming album, Boundary Road, explores many genres from electronic
      folk, electronic, soul to alt-pop. It will take you on a journey away from
      negativity, to reflect upon your life and look forward to your future. She
      recorded and produced the album in her basement studio, working with
      artists around the world during the lockdowns. <br /><br />
      “You cannot deny that Ophelia's Eden was born to make music” - Haumea
      Magazine <br />
      <br />Ciara has been playing and writing songs since she was 5, and for
      the past 4 years sharing her musical gift with others, teaching and
      producing for musicians and artists. And is now embarking on her solo
      journey as Ophelia's Eden with her upcoming debut album in 2023.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  max-width: 900px;
}
</style>