<template>
  <div class="app" :class="mode" role="main">
    <div :class="access">
      <div class="header d-flex justify-content-around"></div>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mode: "light",
      access: "aa",
    };
  },
  methods: {
    toggle() {
      if (this.mode === "dark") {
        this.mode = "light";
      } else {
        this.mode = "dark";
      }
    },
    wcag() {
      if (this.access === "aaa") {
        this.access = "aa";
      } else {
        this.access = "aaa";
      }
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
.aaa .btn {
  font-size: 20px;
  font-weight: bold;
  box-shadow: 4px 4px rgb(0 0 0 / 0.5);
  outline: 2px rgba(0, 0, 0, 0.35) solid;
}
.dark {
  overflow: hidden;
  background: #000;
  background-size: cover;
  height: 100vh;

  .btn {
    background-color: rgba(11, 11, 74, 0.098);
    color: #fff;
  }
}
.header {
  display: none;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.app {
  transition: background 0.3s ease-in-out;
}
.btn {
  color: #000;
  font-weight: 500;
  width: 150px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.3);
  background-color: rgba(255, 255, 255, 0.251);
  backdrop-filter: blur(14px);
  border-radius: 0px;
  @media (max-width: 1000px) {
    width: 80px;
    backdrop-filter: none;
  }
  &:hover {
    color: #fff;
    background-color: rgba(183, 186, 220, 0.381);
    transition: 0.3s all ease-in-out;
  }
}

//animations

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-move {
  transition: transform 0.8s ease;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 1.3s ease-out;
}
.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.slide-right-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 1.3s ease-out;
}
.slide-right-enter-to,
.slide-right-leave-from {
  opacity: 1;
  transform: translateY(0);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #baffc8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  transition: 0.3s;
}
</style>
