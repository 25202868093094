<template>
  <div class="d-flex flex-column">
    <h1 class="text-center p-5">MUSIC.</h1>
    <div class="d-flex justify-content-center">
      <iframe
        style="border-radius: 12px"
        src="https://open.spotify.com/embed/artist/12t8PqLeRbWI5njhsf58CO?utm_source=generator"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  </div>
  <div>
    <h1 class="text-center p-5">MUSIC VIDEOS.</h1>
    <div class="contact d-flex justify-content-center">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/IWbTYIqjHn0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>