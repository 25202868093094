<template>
  <h1 class="text-center p-5">CONTACT.</h1>
  <div class="d-flex justify-content-center">
    <h5 class="text-center">For any enquiries email opheliaseden@gmail.com</h5>
    <!-- <form action="#" class="contact-form">
      <p class="description"></p>
      <div>
        <input
          type="text"
          class="form-control rounded border-white mb-3 form-input"
          id="name"
          placeholder="Name"
          required
        />
      </div>
      <div>
        <input
          type="email"
          class="form-control rounded border-white mb-3 form-input"
          placeholder="Email"
          required
        />
      </div>
      <div>
        <textarea
          id="message"
          class="form-control rounded border-white mb-3 form-text-area"
          rows="5"
          cols="30"
          placeholder="Message"
          required
        ></textarea>
      </div>
      <div class="submit-button-wrapper">
        <input type="submit" value="Send" />
      </div>
    </form> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>